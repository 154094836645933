import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from 'moment';
import styles from './JsonBodyForm.module.css';
import Spinner from "react-bootstrap/Spinner";
import {Trash} from "react-bootstrap-icons";
import UploadFileInput from "./UploadFileInput/UploadFileInput";
import { Typeahead } from "react-bootstrap-typeahead";

function JsonBodyForm(props) {
    const rowInfos = props.rowInfos ? props.rowInfos : [];

    let rowInputs = [];
    rowInfos.forEach((rowInfo) => {
        rowInputs.push(
            <RowInput
                onChange={(fn, v) => props.onRowChange(fn, v)}
                onBlur={rowInfo.onRowBlur}
                fieldName={rowInfo.fieldName}
                label={rowInfo.label}
                placeholder={rowInfo.placeholder}
                infoText={rowInfo.infoText}
                selectCustomOptionsMulti={rowInfo.customOptionsMulti}
                selectCustomOptions={rowInfo.customOptions}
                selectOptions={rowInfo.selectOptions}
                selectValue={rowInfo.selectValue}
                datepicker={rowInfo.datepicker}
                date={rowInfo.date}
                datetimePicker={rowInfo.datetimePicker}
                datetimeValue={rowInfo.datetimeValue}
                checkbox={rowInfo.checkbox}
                checkLabel={rowInfo.checkLabel}
                checkId={rowInfo.checkId}
                checkValue={rowInfo.checkValue}
                isToggleType={rowInfo.isToggleType}
                value={rowInfo.value}
                readOnly={rowInfo.readOnly}
                colSm={props.colSm}
                labelSm={props.labelSm}
                hidden={rowInfo.hidden}
                disabled={rowInfo.disabled}
                customInput={rowInfo.customInput}
                currentCSV={rowInfo.currentCSV}
                uploadResult={rowInfo.uploadResult}
                isMulti={rowInfo.isMulti}
                enableTrash={rowInfo.enableTrash}
                uploadFileInput={rowInfo.uploadFileInput}
                mediaFile={rowInfo.mediaFile}
                mediaFileUrl={rowInfo.mediaFileUrl}
                title={rowInfo.title}
                titleOnly={rowInfo.titleOnly}
                inputSuffix={rowInfo.inputSuffix}
                selectCustomOptionsMulti2={rowInfo.customOptionsMulti2}
            />
        );
    });

    return <Form >
        <Form.Text className="text-muted">
            {props.inputHeader}
        </Form.Text>
        {props.inputHeader ? <br /> : ""}
        {rowInputs}
        {props.disableSubmit ? "" :
            <Button onClick={(e) => { props.onSubmit(e); }}
                    variant="primary">
                {props.isSubmitting && <Spinner animation='border' size='sm' /> }{props.submitText ? props.submitText : "Submit"}
            </Button>}
    </Form>;
}

function RowInput(props) {
    let input = !!props.inputSuffix ? 
        <Row>
            <Col>
                <Form.Control className="form-ctr" placeholder={props.placeholder} value={props.value}
                                onChange={e => props.onChange(props.fieldName, e.target.value)}
                                onBlur={e => typeof(props.onBlur) === 'function' && props.onBlur(props.fieldName, e.target.value)}/>
            </Col>
            <Col className="col-sm-3 d-flex align-items-center pl-0">
                {props.inputSuffix}
            </Col>
        </Row>
    :
        <Form.Control className="form-ctr" placeholder={props.placeholder} value={props.value}
                                onChange={e => props.onChange(props.fieldName, e.target.value)}
                                onBlur={e => typeof(props.onBlur) === 'function' && props.onBlur(props.fieldName, e.target.value)}/>;

    if (props.hidden) {
        return <div></div>;
    }
    
    if (props.titleOnly) {
        return <p style={{fontWeight: 600, textDecoration: 'underline', marginTop: 16, marginBottom: 16}}>{props.title}</p>
    }

    if(props.uploadFileInput) {
        input = <div className="form-ctr">
                    <UploadFileInput
                        isSwitchActive={true}
                        onChangeSwitch={() => {}}
                        fileType={"image/png"}
                        fileSource={props.mediaFile != null ? URL.createObjectURL(props.mediaFile) : props.mediaFileUrl}
                        fileName={props.mediaFile?.name ?? ""}
                        fileAwsUrl={props.mediaFileUrl}
                        onUploadFile={(v) => props.onChange(props.fieldName, v)}
                        ratio="1:1"
                    />
                </div>
    }

    if (props.checkbox) {
        input = <div className="form-ctr">
          <Form.Check
          style={{margin: "auto"}}
          label={props.checkLabel}
          id={props.checkId}
            type={props.isToggleType ? "switch" : "checkbox"}
            checked={props.checkValue}
            disabled={props.disabled}
            onChange={e => props.onChange(props.fieldName, e.target.checked)} />
        </div>;
    }
    else if (props.selectCustomOptions) {
        input = <Form.Control as="select" onChange={e => props.onChange(props.fieldName, e.target.value)}
                              value={props.selectValue} custom>
            {props.selectCustomOptions}
        </Form.Control>;
    } 
    else if (props.selectOptions) {
        let options = [];
        props.selectOptions.forEach((option) => {
            options.push(
                <option value={option}>{option}</option>
            );
        });
        input = <Form.Control as="select" onChange={e => props.onChange(props.fieldName, e.target.value)}
                              value={props.selectValue} custom>
            {options}
        </Form.Control>;
    }
    else if (props.selectCustomOptionsMulti2) {
        input = <Typeahead
            maxResults={30}
            options={props.selectCustomOptionsMulti2}
            clearButton
            selected={props.selectValue}
            onChange={async (selected) => {
                const isMulti = props.isMulti ?? false;
                props.onChange(props.fieldName, isMulti ? selected : selected[0]);
            }}
            multiple={props.isMulti !== false}
        />
    }
    else if (props.selectCustomOptionsMulti) {
        input = <Select
            /* value={props.selectCustomOptionsMulti.filter(function (option) {
                return option.value === props.selectValue;
            })} */
            value={props.selectValue}
            onChange={(e, a) => {
                if (a.action === "select-option" || a.action === "remove-value") {
                    // some exception
                    props.onChange(props.fieldName, e);
                } else if (a.action === "clear") {
                    props.onChange(props.fieldName, '');
                }
            }}
            placeholder={props.placeholder}
            options={props.selectCustomOptionsMulti}
            isMulti={props.isMulti !== false}
            isDisabled={props.disabled === true}
            isSearchable
            isClearable
        />;
    }
    else if (props.readOnly) {
        input = <input name={props.fieldName} value={props.value} className={'form-control'} readOnly/>
    }
    else if (props.datepicker) {
        input = <DatePicker
            selected={props.date}
            onChange={date => {props.onChange(props.fieldName, date)}}
            className='form-control'
            placeholderText='dd-mm-yyyy'
            dateFormat='dd-MM-yyyy'
        />;
    }
    else if (props.currentCSV){
        // props.uploadResult = []
        input =<input
            type='file'
            id='csvFile'
            accept=".csv"
            onChange={(e) => props.onChange(props.fieldName, e.currentTarget.files[0])}
        />

    }

    if (props.datetimePicker){
        input = <DatePicker
            selected={(props.datetimeValue && moment(props.datetimeValue).toDate())}
            onChange={date => props.onChange(props.fieldName, moment(date).format('YYYY-MM-DDTHH:mm:00'))}
            className='form-control'
            wrapperClassName={styles.dtWrapper}
            showTimeSelect
            placeholderText='dd-mm-yyyy hh:mm'
            timeFormat='HH:mm'
            dateFormat='dd-MM-yyyy HH:mm'
        />
    }

    if (props.customInput){
        input = props.customInput;
    }

    // if (csvFile !== undefined){
    //     const file = csvFile;
    //     const reader = new FileReader();
    //
    //     reader.onload = function(e) {
    //         const text = e.target.result;
    //         props.uploadResult.push(text)
    //     }
    //
    //     reader.readAsText(file);
    // }

    return <Form.Group as={Row} className="form-prop">
        <Form.Label column sm={props.labelSm ? props.labelSm :'1'}>{props.label}</Form.Label> 
        <Col sm={props.colSm ? props.colSm :'10'}>
            {input}
            <Form.Text className="text-muted">
                {props.infoText}
            </Form.Text>
        </Col>
        {
            props.enableTrash ?
                (<div className='mt-1 ml-4'>
                    <Trash className={styles.pointer} onClick={() => {props.onChange(props.fieldName, null)}}/>
                </div>) :
                <></>
        }
    </Form.Group>;
}

export default JsonBodyForm;