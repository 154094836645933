import { Field, useFormikContext } from "formik";
import React, { memo, useMemo } from "react";
import { Table } from "antd";
import styles from "../../PurchaseOrder.module.scss";
import Autocomplete from "react-autocomplete";
import { QuestionCircleFill, Trash } from "react-bootstrap-icons";
import 'antd/dist/antd.css';
import 'antd/es/style/themes/default.less';
import * as utils from "../../utils/utils";
import { calculatePredictedQuantity } from "../../utils/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CurrencyField from "../../../../components/CustomInput/CurrencyField/CurrencyField";
import { handleDecimalInput } from "../../../../utils/InputUtils";

const innerPackQty = (predictedQuantity, customQuantity) => {
  if (Number.isNaN(predictedQuantity / customQuantity) || customQuantity == null || customQuantity === 0)
    return '-';
  return (predictedQuantity / customQuantity).toFixed(3);
}

function ItemTable(props) {
  const { values, setFieldValue } = useFormikContext();
  const { items, readOnly } = values;

  const tableData = useMemo(
    () => Object.entries(items).map(([, value]) => value),
    [items]
  );

  const onUpdateTableData = (key, field, value) => {
    if (key in items) {
      let newItem = { ...items[key] };
      newItem[field] = value;
      if (["customPurchasingUnit", "customQuantity", "predictedQuantity"].includes(field)) {
        newItem = calculatePredictedQuantity(newItem, field);
      }
      setFieldValue(`items.${key}`, newItem);
    }
  };

  const deleteTableRow = (key) => {
    if (key in items) {
      const newItems = { ...items };
      delete newItems[key];
      setFieldValue('items', newItems);
    }
  };

  const RenderQty = (value) => {
    return (
      <Field
        name={`dynamic.${value.itemType}-${value.sku}.quantity`}
        type="text"
        onChange={(e) => onUpdateTableData(value.key, "quantity", e.target.value)}
        className="form-control"
        value={!value.isCustom ? value.quantity : ''}
        disabled={value.isCustom || readOnly}
        onInput={handleDecimalInput}
      />
    );
  };
  RenderQty.displayName = "RenderQty";

  const RenderUnit = (value) => {
    return (
      <Field
        name={`dynamic.${value.itemType}-${value.sku}.unit`}
        component='textarea'
        className={`form-control ${!value.isCustom ? styles.readOnly : ''}`}
        value={!value.isCustom ? value.unit : ''}
        disabled={true}
      />
    );
  };
  RenderUnit.displayName = "RenderUnit";

  const RenderPricePerUnit = (value) => {
    return (
      <CurrencyField
        name={`dynamic.${value.itemType}-${value.sku}.pricePerUnit`}
        value={!value.isCustom ? value.pricePerUnit : ''}
        onChange={(val) => onUpdateTableData(value.key, "pricePerUnit", val)}
        disabled={value.isCustom || readOnly}
        separator=','
        currency='Rp'
        required={true}
      />
    );
  };
  RenderPricePerUnit.displayName = "RenderPricePerUnit";

  const RenderCustom = (value) => {
    return (
      <Field
        name={`dynamic.${value.itemType}-${value.sku}.custom`}
        type="checkbox"
        onClick={() => onUpdateTableData(value.key, "isCustom", !value.isCustom)}
        className="form-check-item mx-auto d-block"
        checked={value.isCustom}
        disabled={readOnly}
      />
    );
  };
  RenderCustom.displayName = "RenderCustom";

  const RenderPurchaseQty = (value) => {
    return (
      <Field
        name={`dynamic.${value.itemType}-${value.sku}.customQty`}
        type="text"
        onChange={(e) => onUpdateTableData(value.key, "customQuantity", e.target.value)}
        className="form-control"
        value={value.isCustom ? value.customQuantity : ''}
        disabled={!value.isCustom || readOnly}
        onInput={handleDecimalInput}
      />
    );
  };
  RenderPurchaseQty.displayName = "RenderPurchaseQty";

  const RenderPurchaseUnit = (value) => {
    return (
      <Autocomplete
        items={value.uomSuggestion === undefined ? [] : value.uomSuggestion}
        getItemValue={(item) => item.name}
        renderItem={(item, isHighlighted) =>
          <div className='react-autocomplete' style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
            {item.name}
          </div>
        }
        onChange={(e) => onUpdateTableData(value.key, "customPurchasingUnit", e.target.value)}
        onSelect={(val) => onUpdateTableData(value.key, "customPurchasingUnit", val)}
        name={`dynamic.${value.itemType}-${value.sku}.customUnit`}
        value={value.isCustom ? (value.customPurchasingUnit ? value.customPurchasingUnit : '') : ''}
        inputProps={{
          disabled: !value.isCustom || readOnly,
          className: "form-control",
          type: "text",
          name: `dynamic.${value.itemType}-${value.sku}.customUnit`,
        }}
        menuStyle={{ overflow: 'visible', zIndex: 10, position: 'absolute', left: 'auto', top: 'auto' }}
        wrapperStyle={{ display: 'inline-block' }}
      />
    );
  };
  RenderPurchaseUnit.displayName = "RenderPurchaseUnit";

  const RenderPricePerPurchaseUnit = (value) => {
    return (
      <CurrencyField
        name={`dynamic.${value.itemType}-${value.sku}.pricePerUnit`}
        value={value.isCustom ? value.pricePerUnit : ''}
        onChange={(val) => onUpdateTableData(value.key, "pricePerUnit", val)}
        disabled={!value.isCustom || readOnly}
        separator=','
        currency='Rp'
        required={true}
      />
    );
  };
  RenderPricePerPurchaseUnit.displayName = "RenderPricePerPurchaseUnit";

  const PredictedInventoryTitle = () => (
    <div>
      <p>
        Predicted Inventory Qty
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              Jumlah perkiraan item yang datang ke gudang dalam unit inventory
            </Tooltip>
          )}
        >
          <QuestionCircleFill className='ml-1 mb-1' />
        </OverlayTrigger>
      </p>
    </div>
  );
  PredictedInventoryTitle.displayName = "PredictedInventoryTitle";

  const RenderPredictedQuantity = (value) => {
    return (
      <Field
        name={`dynamic.${value.itemType}-${value.sku}.predictedQuantity`}
        type="text"
        onChange={(e) => onUpdateTableData(value.key, "predictedQuantity", e.target.value)}
        className="form-control"
        value={value.isCustom ? value.predictedQuantity : ''}
        disabled={!value.isCustom || value.isSuggestedUOM || readOnly}
        onInput={handleDecimalInput}
      />
    );
  };
  RenderPredictedQuantity.displayName = "RenderPredictedQuantity";

  const InnerpackTitle = () => (
    <div>
      <p>
        Innerpack Qty
        <OverlayTrigger
          placement="bottom"
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              Predicted Inventory Qty dibagi dengan Purchase Qty
            </Tooltip>
          )}
        >
          <QuestionCircleFill className='ml-1 mb-1' />
        </OverlayTrigger>
      </p>
    </div>
  );
  InnerpackTitle.displayName = "InnerpackTitle";

  const RenderInnerpackQty = (value) => {
    return (
      <Field
        disabled={true}
        value={!value.isCustom ? '-' : innerPackQty(value.predictedQuantity, value.customQuantity)}
        className="form-control"
      />
    );
  };
  RenderInnerpackQty.displayName = "RenderInnerpackQty";

  const RenderInventoryUnit = (value) => {
    return (
      <Field
        name={`dynamic.${value.itemType}-${value.sku}.inventoryUnit`}
        component='textarea'
        className={`form-control ${value.isCustom ? styles.readOnly : ''}`}
        value={value.isCustom ? value.unit : ''}
        disabled={true}
      />
    );
  };
  RenderInventoryUnit.displayName = "RenderInventoryUnit";

  const RenderGramation = (value) => {
    return (
      <Field
        name={`dynamic.${value.itemType}-${value.sku}.gramationPerQuantity`}
        type="text"
        onChange={(e) => onUpdateTableData(value.key, "gramationPerQuantity", e.target.value)}
        className="form-control"
        value={value.gramationPerQuantity}
        disabled={readOnly}
        onInput={handleDecimalInput}
      />
    );
  };
  RenderGramation.displayName = "RenderGramation";

  const RenderAction = (value) => {
    return (
      <Button
        variant='outline-danger'
        style={{ borderColor: "transparent" }}
        disabled={readOnly}
        onClick={() => deleteTableRow(value.key)}
      >
        <Trash size='32' />
      </Button>
    );
  };
  RenderAction.displayName = "RenderAction";

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      align: 'left',
    },
    {
      title: 'SKU ID',
      dataIndex: 'sku',
      key: 'sku',
      align: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'itemType',
      key: 'itemType',
      align: 'left',
    },
    {
      title: 'Qty',
      key: 'qty',
      render: RenderQty,
    },
    {
      title: 'Unit',
      key: 'unit',
      render: RenderUnit,
    },
    {
      title: 'Price per Unit',
      key: 'pricePerUnit',
      width: 300,
      render: RenderPricePerUnit,
    },
    {
      title: 'Custom?',
      key: 'custom',
      render: RenderCustom,
    },
    {
      title: 'Purchase Qty',
      key: 'purchaseQty',
      render: RenderPurchaseQty,
    },
    {
      title: 'Purchase Unit',
      key: 'purchaseUnit',
      render: RenderPurchaseUnit,
    },
    {
      title: 'Price per Purchase Unit',
      key: 'pricePerUnit',
      width: 300,
      render: RenderPricePerPurchaseUnit,
    },
    {
      title: PredictedInventoryTitle,
      key: 'predictedQuantity',
      render: RenderPredictedQuantity,
    },
    {
      title: InnerpackTitle,
      key: 'innerpackQty',
      render: RenderInnerpackQty,
    },
    {
      title: 'Inventory Unit',
      key: 'Inventory Unit',
      render: RenderInventoryUnit,
    },
    {
      title: 'Gramation',
      key: 'gramation',
      render: RenderGramation,
    },
    {
      title: 'Action',
      key: 'action',
      render: RenderAction,
    },
  ];

  return items && !utils.isEmpty(items) ? (
    <div className='mb-4'>
      <div>
        <h4>Tabel Item</h4>
      </div>
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false}
        scroll={{ x: 3000, y: 2000 }}
      />
    </div>
  ) : <div></div>;
}

export default memo(ItemTable);
