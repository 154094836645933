export function mapOptions({products}) {
    let productOpts = [];
    if (!!products) {
        products.map((item) => {
            productOpts.push({
                label: `${item.skuId} - ${item.name}`,
                value: item.id
            });
        });
    }
    return {productOpts};
}

export function mapAgentPriceGroupOptions({agentPriceGroups}) {
    let agentPriceGroupOpts = [];
    if (!!agentPriceGroups) {
        agentPriceGroups.forEach((item) => {
            agentPriceGroupOpts.push({
                label: `${item.id} - ${item.name}`,
                value: item.id
            });
        });
    }
    return {agentPriceGroupOpts};
}

export function mapPromoLabelOptions({promoLabels}) {
    let promoLabelOpts = [];
    if (!!promoLabels) {
        promoLabels.map((item) => {
            promoLabelOpts.push({
                value: item.id,
                label: `${item.name}`
            });
        });
    }
    return {promoLabelOpts};
}

export function mapOfflineStoreSellingUnitAndAmount({products}) {
    let productOfflineStoreSellingUnitAndAmountMap = {};
    if (!!products) {
        products.forEach((item) => {
            if (!!item.offlineStoreSellingUnit && !!item.offlineStoreSellingUnitAmount) {
                productOfflineStoreSellingUnitAndAmountMap[item.id] = {
                    offlineStoreSellingUnit: item.offlineStoreSellingUnit,
                    offlineStoreSellingUnitAmount: item.offlineStoreSellingUnitAmount,
                };
            }
        });
    }
    return {productOfflineStoreSellingUnitAndAmountMap};
}