export const handleDecimalInput = (e) => {
  const value = e.target.value;
  // Replace any commas with periods and remove any extra decimal points
  const sanitizedValue = value.replace(/,/g, '.').replace(/(\..*)\./g, '$1');
  
  if (sanitizedValue.includes('.')) {
      const decimals = sanitizedValue.split('.')[1];
      if (decimals.length > 3) {
          e.target.value = Number(sanitizedValue).toFixed(3);
      } else {
          e.target.value = sanitizedValue;
      }
  } else {
      e.target.value = sanitizedValue;
  }
}