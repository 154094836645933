import {Col, Form} from "react-bootstrap";
import Select from "react-select";
import React, {memo, useEffect, useMemo, useState} from "react";
import Row from "react-bootstrap/Row";
import {displayAlert} from "../../../../redux/actions/notif";
import {useDispatch, } from "react-redux";
import {useFormikContext} from "formik";
import * as apiProduct from "../../../../apis/products";
import {PRODUCT} from "../../../../constants/itemType";
import * as utils from "../../utils/utils";
import {createProductAndPurchasingProductOptions, setUOMSuggestion} from "../../utils/utils";
import UploadCSVButton from "../UploadCSVButton";
import { Accordion, Button, Card } from "react-bootstrap";
import { formatDecimalNumber } from "../../../../utils/Formatter";

function ItemCard(props) {
    const {values, setFieldValue} = useFormikContext();
    const [csv, setCsv] = useState(null);
    const {items,  readOnly} = values;
    // Redux Hook
    const dispatch = useDispatch();

    // fetchedData
    const [product, setProduct] = useState([]);
    const [purchasableProduct, setPurchasableProduct] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);

    // Item Option
    const productOptions = useMemo(
        () => createProductAndPurchasingProductOptions(purchasableProduct, PRODUCT),
        [purchasableProduct]);

    const[tempItems, setTempItems] = useState({})

    useEffect(() => {
        function handleResponse(response) {
            setProduct(response.product.data.data.data);
            const purchasableProducts = response.product.data.data.data.filter(pr => pr.isPurchasable === true && pr.isActive === true);
            setPurchasableProduct(purchasableProducts);
        }

        function handleProps() {
            const purchasableProducts = props.products.filter(pr => pr.isPurchasable === true && pr.isActive === true);
            setProduct(props.products)
            setPurchasableProduct(purchasableProducts);
        }

        // handle props instead of fetch new product
        if(props.products !== undefined && props.products?.length > 0) {
            handleProps();
            return;
        }

        const fetch = async () => {
            try {
                const productPromise = apiProduct.getProductsLight();
                const [productResponse] = await Promise.all([
                    productPromise
                ])

                handleResponse({
                    product: productResponse,
                })

            } catch (e) {
                dispatch(displayAlert({ message: e.message, type: 'error' }));
            }
        }
        fetch().then(() => {});
    }, []);

    const generateTable = () => {
        const insert = async (e) => {
            const key = e.placeholder.key;
            let value = e.placeholder;
            value = {...value, key}
            value = await setUOMSuggestion(value);
            if(!(key in items)){
                setFieldValue(`items.${key}`, value);
            }
        }

        if (tempItems && !utils.isEmpty(tempItems)) {
            tempItems.forEach(e => {
                    insert(e).then(() => {});
                }
            );
        }
    }

    const csvReader = async (lines) => {
        if (product.length === 0) {
            dispatch(displayAlert({ message: ("Product belum selesai dimuat "), type: 'error' }));
            return;
        }
        let deactivatedItems = [];
        let nonPurchasableItems = [];
        let invalidSkuIdItems = [];
        let invalidCustomItems = [];
        let errorRowSet = new Set();
        let successItems = new Map();

        lines.slice(1).forEach(async (line, index) => {
            const cells = line.split(",");

            if (cells.length <= 1) return;

            try {
                let exists;
                let key;
                let value;

                let skuId = cells[0];
                let quantity = formatDecimalNumber(cells[1]);
                let pricePerUnit = cells[2];
                let isCustom = cells[3].toLowerCase() === "yes";
                let customUnit = cells[4];
                let predictedQuantity = formatDecimalNumber(cells[5]);
                let gramationPerQuantity = formatDecimalNumber(cells[6]);

                if (!skuId || skuId === "") {
                    return;
                }

                if (isCustom && (!predictedQuantity || !customUnit || customUnit === "")) {
                    invalidCustomItems.push(skuId)
                    errorRowSet.add(index);
                }

                exists = product.find(e => e.skuId === skuId);

                if (!exists) {
                    invalidSkuIdItems.push(skuId);
                    errorRowSet.add(index);
                    return;
                }

                if (exists?.isPurchasable === false) {
                    nonPurchasableItems.push(exists.skuId);
                    errorRowSet.add(index);
                    return;
                }

                if (exists?.isActive === false) {
                    deactivatedItems.push(exists.skuId);
                    errorRowSet.add(index);
                    return;
                } else {
                    key = `${PRODUCT}|${exists.id}`;

                    value = {
                        itemType: PRODUCT,
                        sku: exists.skuId,
                        name: exists.name,
                        unit: exists.sellingUnit,
                        pricePerUnit: pricePerUnit,
                        isCustom: isCustom,
                        quantity: isCustom ? null : quantity,
                        customQuantity: isCustom ? quantity : null,
                        predictedQuantity: isCustom ? predictedQuantity : null,
                        customPurchasingUnit: isCustom ? customUnit : null,
                        isGramationPerQuantityActive: gramationPerQuantity != null && gramationPerQuantity !== '',
                        gramationPerQuantity: gramationPerQuantity,
                        key,
                    }
                }

                if (!!exists && !(key in items) && !exists.deactivatedAt) {
                    successItems.set(`items.${key}`, value);
                }

            } catch (e) {
                dispatch(displayAlert({ message: e.message, type: 'error' }))
            }
        });

        let localErrorMessage = [];

        if (invalidCustomItems.length) {
            localErrorMessage.push("Terdapat SKU custom tidak memiliki custom purchasing unit atau predicted quantity: " + invalidCustomItems.join(", ") + ".");
        }

        if (deactivatedItems.length) {
            localErrorMessage.push("Terdapat SKU inactive: " + deactivatedItems.join(", ") + ".");
        }

        if (nonPurchasableItems.length) {
            localErrorMessage.push("Terdapat SKU non-purchasable: " + nonPurchasableItems.join(", ") + ".");
        }

        if (invalidSkuIdItems.length) {
            localErrorMessage.push("Terdapat SKU invalid / tidak terdaftar pada master data: " + invalidSkuIdItems.join(", ") + ".");
        }

        if (errorRowSet.size) {
            const actualCsvRow = Array.from(errorRowSet).map((element) => element + 2);
            localErrorMessage.push("Terdapat Error pada baris: " + actualCsvRow.join(', ') + ".");
        }

        if (localErrorMessage.length > 0) {
            setErrorMessage(localErrorMessage);
            return;
        }

        successItems.forEach((value, key) => {
            setFieldValue(key, value);
        });
        setErrorMessage([]);
    }

    const onBulkUpload = () => {
        if (csv) {
            const file = csv;
            const reader = new FileReader();
            reader.onload = (e) => {
                const csv = reader.result;
                const lines = csv.split("\n");
                csvReader(lines);
            };
            reader.readAsText(file);
        }
    }



    return readOnly
        ? <div></div>
        : <div className='internal-body-div mb-5'>
            <h5>C. Item</h5>
            <hr/>
            <div>
                <Row>
                    <Col>
                        <SelectItemField
                            options={productOptions}
                            setTempItems={setTempItems}
                        />
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label/>
                            <div className='mt-2'>
                                <Button
                                    style={{
                                        backgroundColor: 'transparent',
                                        color: tempItems && !utils.isEmpty(tempItems) ? '#0C7800' : 'grey',
                                        borderColor: "transparent",
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                    disabled={!tempItems || utils.isEmpty(tempItems)}
                                    onClick={() => generateTable()}
                                >
                                    Tambah ke tabel item
                                </Button>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <hr/>
                <BulkUploadSection
                    csv={csv}
                    setCsv={setCsv}
                    onUpload={onBulkUpload}
                />
                {errorMessage.length > 0 && <Row><Col>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header bg="danger" style={{ backgroundColor: '#f8d7da' }} >
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Lihat Error CSV
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body style={{
                                    fontSize: '14px',
                                }}>
                                    {errorMessage.map((message, index) => (
                                        <div key={index}>{message}</div>
                                    ))}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col></Row>}
            </div>
        </div>
}

function SelectItemField(props){
    const { options, setTempItems } = props
    return <Form.Group>
        <Form.Label>Item</Form.Label>
        <div>
            <Select
                name='items'
                onChange={(e, actionType) => {
                    actionType.action === 'clear' ? setTempItems({}) : setTempItems(e)
                }}
                options={options}
                isClearable={true}
                isMulti={true}
            />
            <small className="form-text text-muted">Bisa pilih lebih dari 1</small>
        </div>
    </Form.Group>
}

function BulkUploadSection(props){
    const {csv, setCsv, onUpload} = props;
    return <Row>
        <Col>
            <Row>
                <Col>
                    <p>
                        Bulk Add Items <br/>
                        <small className='text-muted'>
                            Download Template CSV&nbsp;
                            <a href="https://docs.google.com/spreadsheets/d/1OOcufwNcZXr-FyZQQio-uh2TjH2rN5UQGEaJ0GjCqfA/edit?usp=sharing"
                               target="_blank">
                                disini
                            </a>
                        </small>
                    </p>
                </Col>
            </Row>
            <Row style={{width: '75%'}}>
                <Col>
                    <div
                        className='bg-light pl-3 pb-2 pt-2 rounded'
                    >
                        <UploadCSVButton
                            onChange={(files) => {
                                files[0] && setCsv(files[0])
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <Button
                        style={{
                            backgroundColor: 'transparent',
                            color: csv ? '#0C7800' : 'grey',
                            borderColor: "transparent",
                            fontSize: '14px',
                            fontWeight: 'bold',
                        }}
                        disabled={!csv}
                        onClick={onUpload}
                    >
                        Tambah ke tabel item
                    </Button>
                </Col>
            </Row>
        </Col>
    </Row>
}

export default memo(ItemCard);