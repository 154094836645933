import moment from "moment";
import {PRODUCT, PURCHASING_PRODUCT} from "../../../constants/itemType";
import * as apiUnitOfMeasure from "../../../apis/unitOfMeasures";
import { PURCHASE_ORDER_TYPE_LIST_OPTIONS} from "../../../constants/purchaseOrder";

export function isEmpty(obj) {
    return obj == null || Object.keys(obj).length === 0;
}

export function formatDateTime(date, format = 'DD-MM-YYYY', timezone = "Asia/Jakarta") {
    return moment(date).tz(timezone).format(format);
}

export const setUOMSuggestion = async (value) => {
    if (value['uomSuggestion'] !== undefined) return value;
    try {
        let itemSuggestionsPromise;
        if (value['itemType'] === PRODUCT) {
            itemSuggestionsPromise = apiUnitOfMeasure.getProductSuggestions({ id: value.sku })
        } else if (value['itemType'] === PURCHASING_PRODUCT) {
            itemSuggestionsPromise = apiUnitOfMeasure.getPurchasingProductSuggestions({ id: value.sku })
        }
        const [itemSuggestionsResponse] = await Promise.all([
            itemSuggestionsPromise,
        ])
        value['uomSuggestion'] = itemSuggestionsResponse.data.data;
    } catch (e) {
        value['uomSuggestion'] = [];
    }
    return value;
}

export const getSelectedUOM = (prevState) => {
    if (!prevState || !prevState['uomSuggestion']) { return null }
    const uomSuggestion = prevState['uomSuggestion'].filter(e => e.name === prevState["customPurchasingUnit"])
    return uomSuggestion
}

export const calculatePredictedQuantity = (prevState) => {
    const removeTrailingZero = (val) => {
        return parseFloat(val);
    }

    const newState = { ...prevState };
    const uomSuggestion = getSelectedUOM(newState);
    if (!!uomSuggestion && uomSuggestion.length > 0) {
        newState["isSuggestedUOM"] = true;
        const predictedQuantity = uomSuggestion[0].innerPackQuantity * newState["customQuantity"];
        newState["predictedQuantity"] = removeTrailingZero(predictedQuantity.toFixed(3));
    }
    else {
        newState["isSuggestedUOM"] = false;
    }
    return newState
}

export const createProductAndPurchasingProductOptions = (items, itemType) => {
    return items
        .map(e => {
            const sku = itemType === PRODUCT ? e.skuId : e.purchasingSku;
            const unit = itemType === PRODUCT ? e.sellingUnit : e.purchasingUnit;
            const key = `${itemType}|${e.id}`;
            return {
                value: e.id,
                label: `${sku} - ${e.internalName ?? e.name} - ${unit}`,
                placeholder: {
                    sku: sku,
                    name: e.internalName ?? e.name,
                    itemType: itemType,
                    unit: unit,
                    quantity: null,
                    gramationPerQuantity: null,
                    isGramationPerQuantityActive: false,
                    pricePerUnit: 0,
                    isCustom: false,
                    customQuantity: null,
                    customPurchasingUnit: null,
                    predictedQuantity: null,
                    key: key,
                }
            }
        });
}

export function getPurchaseOrderTypeLabelByValue(value) {
    const option = PURCHASE_ORDER_TYPE_LIST_OPTIONS.find(option => option.value === value);
    return option ? option.label : null;
}